import * as _froalaEditor2 from "froala-editor";

var _froalaEditor = "default" in _froalaEditor2 ? _froalaEditor2.default : _froalaEditor2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*!
 * froala_editor v4.0.15 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2022 Froala Labs
 */
!function (e, t) {
  t(_froalaEditor);
}(exports, function (i) {
  "use strict";

  i = i && i.hasOwnProperty("default") ? i["default"] : i, Object.assign(i.DEFAULTS, {
    listAdvancedTypes: !0
  }), i.PLUGINS.lists = function (m) {
    var g = m.$;

    function d(e) {
      return "<span class=\"fr-open-".concat(e.toLowerCase(), "\"></span>");
    }

    function f(e) {
      return "<span class=\"fr-close-".concat(e.toLowerCase(), "\"></span>");
    }

    function o(e, t) {
      !function c(e, t) {
        for (var a = [], n = 0; n < e.length; n++) {
          var r = e[n].parentNode;
          "LI" == e[n].tagName && r.tagName != t && a.indexOf(r) < 0 && a.push(r);
        }

        for (var o = a.length - 1; 0 <= o; o--) {
          var l = g(a[o]);
          l.replaceWith("<".concat(t.toLowerCase(), " ").concat(m.node.attributes(l.get(0)), ">").concat(l.html(), "</").concat(t.toLowerCase(), ">"));
        }
      }(e, t);
      var a,
          n = m.html.defaultTag(),
          r = null;
      e.length && (a = "rtl" == m.opts.direction || "rtl" == g(e[0]).css("direction") ? "margin-right" : "margin-left");

      for (var o = 0; o < e.length; o++) if ("TD" != e[o].tagName && "TH" != e[o].tagName && "LI" != e[o].tagName) {
        var l = m.helpers.getPX(g(e[o]).css(a)) || 0;
        (e[o].style.marginLeft = null) === r && (r = l);
        var i = 0 < r ? "<".concat(t, " style=\"").concat(a, ": ").concat(r, "px \">") : "<".concat(t, ">"),
            s = "</".concat(t, ">");

        for (l -= r; 0 < l / m.opts.indentMargin;) i += "</".concat(t, ">"), s += s, l -= m.opts.indentMargin;

        n && e[o].tagName.toLowerCase() == n ? g(e[o]).replaceWith("".concat(i, "<li").concat(m.node.attributes(e[o]), ">").concat(g(e[o]).html(), "</li>").concat(s)) : g(e[o]).wrap("".concat(i, "<li></li>").concat(s));
      }

      m.clean.lists();
    }

    function c(e) {
      var t, a;

      for (t = e.length - 1; 0 <= t; t--) for (a = t - 1; 0 <= a; a--) {
        if (g(e[a]).find(e[t]).length || e[a] == e[t]) {
          e.splice(t, 1);
          break;
        }

        if (g(e[a]).contains(e[t])) {
          e.splice(a, 1);
          break;
        }
      }

      var n = [];

      for (t = 0; t < e.length; t++) {
        var r = g(e[t]),
            o = e[t].parentNode,
            l = r.attr("class");
        if (r.before(f(o.tagName)), "LI" == o.parentNode.tagName) r.before(f("LI")), r.after(d("LI"));else if ("OL" == o.parentNode.tagName) r.before(f("OL")), r.after(d("OL"));else if ("UL" == o.parentNode.tagName) r.before(f("UL")), r.after(d("UL"));else {
          var i = "";
          l && (i += " class=\"".concat(l, "\""));
          var s = "rtl" == m.opts.direction || "rtl" == r.css("direction") ? "margin-right" : "margin-left";
          m.helpers.getPX(g(o).css(s)) && 0 <= (g(o).attr("style") || "").indexOf("".concat(s, ":")) && (i += " style=\"".concat(s, ":").concat(m.helpers.getPX(g(o).css(s)), "px;\"")), m.html.defaultTag() && 0 === r.find(m.html.blockTagsQuery()).length && r.wrapInner(m.html.defaultTag() + i), m.node.isEmpty(r.get(0), !0) || 0 !== r.find(m.html.blockTagsQuery()).length || ("DIV" == o.parentNode.tagName && m.html.defaultTag() ? (r.prepend(d(m.html.defaultTag())), r.append(f(m.html.defaultTag()))) : r.append("<br>")), r.append(d("LI")), r.prepend(f("LI"));
        }
        r.after(d(o.tagName)), "LI" == o.parentNode.tagName && (o = o.parentNode.parentNode), n.indexOf(o) < 0 && n.push(o);
      }

      for (t = 0; t < n.length; t++) {
        var c = g(n[t]),
            p = c.html();
        p = (p = p.replace(/<span class="fr-close-([a-z]*)"><\/span>/g, "</$1>")).replace(/<span class="fr-open-([a-z]*)"><\/span>/g, "<$1>"), c.replaceWith(m.node.openTagString(c.get(0)) + p + m.node.closeTagString(c.get(0)));
      }

      m.$el.find("li:empty").remove(), m.$el.find("ul:empty, ol:empty").remove(), m.clean.lists(), m.$el.find("ul:empty, ol:empty").remove(), m.html.wrap();
    }

    function e(e) {
      if ("indent" == e || "outdent" == e) {
        var t = !1,
            a = m.selection.blocks(),
            n = [],
            r = a[0].previousSibling || a[0].parentElement;

        if ("outdent" == e) {
          if ("UL" != a[0].parentNode.tagName && "UL" != r.parentNode.tagName && "OL" != r.parentNode.tagName && "LI" != r.parentNode.tagName) return;
          if (!a[0].previousSibling && "none" == r.parentNode.style.listStyleType) return void function l(e) {
            for (m.selection.save(); 0 < e.childNodes.length;) e.parentNode.parentNode.append(e.childNodes[0]);

            m.clean.lists(), m.selection.restore();
          }(r);
        } else {
          if ("UL" != a[0].parentElement.tagName && "OL" != a[0].parentElement.tagName && "LI" != a[0].parentElement.tagName) return;
          if (m.node.isBlock(a[0]) && "LI" !== a[0].tagName && 0 < g(a[0]).parentsUntil(m.$el, "LI").length && (a[0] = a[0].parentElement, r = a[0].parentElement), !a[0].previousSibling || "LI" != a[0].previousSibling.tagName) return void function i(e) {
            m.selection.save();

            for (var t = "OL" == e.tagName ? document.createElement("ol") : document.createElement("ul"); 0 < e.childNodes.length;) t.append(e.childNodes[0]);

            var a = document.createElement("li");
            t.append(a), e.append(t), m.clean.lists(), m.selection.restore();
          }(r);
        }

        for (var o = 0; o < a.length; o++) "LI" == a[o].tagName ? (t = !0, n.push(a[o])) : "LI" == a[o].parentNode.tagName && (t = !0, n.push(a[o].parentNode));

        t && ("indent" == e ? function f(e) {
          m.selection.save();

          for (var t = 0; t < e.length; t++) {
            var a = e[t].previousSibling;

            if (a) {
              var n = g(e[t]).find("> ul, > ol").last().get(0);

              if (n) {
                var r = g(document.createElement("li"));
                if (n.childNodes.length) for (var o = n.childNodes[0].attributes, l = 0; l < o.length; l++) {
                  var i = o[l];
                  "style" === i.name && (r[0].style.cssText += i.value);
                }
                g(n).prepend(r);

                for (var s = m.node.contents(e[t])[0]; s && !m.node.isList(s);) {
                  var c = s.nextSibling;
                  r.append(s), s = c;
                }

                g(a).append(g(n)), g(e[t]).remove();
              } else {
                var p = g(a).find("> ul, > ol").last().get(0);
                if (p) g(p).append(g(e[t]));else {
                  var d = g("<".concat(e[t].parentNode.tagName, ">"));
                  g(a).append(d), d.append(g(e[t]));
                }
              }
            }
          }

          m.clean.lists(), m.selection.restore();
        }(n) : function s(e) {
          m.selection.save(), c(e), m.selection.restore();
        }(n));
      }
    }

    return {
      _init: function t() {
        m.events.on("commands.after", e), m.events.on("keydown", function (e) {
          if (e.which == i.KEYCODE.TAB) {
            for (var t = m.selection.blocks(), a = [], n = 0; n < t.length; n++) "LI" == t[n].tagName ? a.push(t[n]) : "LI" == t[n].parentNode.tagName && a.push(t[n].parentNode);

            if (1 < a.length || a.length && (m.selection.info(a[0]).atStart || m.node.isEmpty(a[0]))) return e.preventDefault(), e.stopPropagation(), e.shiftKey ? m.commands.outdent() : m.commands.indent(), !1;
          }
        }, !0);
      },
      format: function l(e, t) {
        var a, n;

        for (m.html.syncInputs(), m.selection.save(), m.html.wrap(!0, !0, !0, !0), m.selection.restore(), n = m.selection.blocks(!0), a = 0; a < n.length; a++) "LI" != n[a].tagName && "LI" == n[a].parentNode.tagName && (n[a] = n[a].parentNode);

        if (m.selection.save(), function r(e, t) {
          for (var a = !0, n = 0; n < e.length; n++) {
            if ("LI" != e[n].tagName) return !1;
            e[n].parentNode.tagName != t && (a = !1);
          }

          return a;
        }(n, e) ? t || c(n) : o(n, e), m.html.unwrap(), m.selection.restore(), t = t || "default") {
          for (n = m.selection.blocks(), a = 0; a < n.length; a++) "LI" != n[a].tagName && "LI" == n[a].parentNode.tagName && (n[a] = n[a].parentNode);

          for (a = 0; a < n.length; a++) "LI" == n[a].tagName && (g(n[a].parentNode).css("list-style-type", "default" === t ? "" : t), 0 === (g(n[a].parentNode).attr("style") || "").length && g(n[a].parentNode).removeAttr("style"));
        }
      },
      refresh: function r(e, t) {
        var a = g(m.selection.element());

        if (a.get(0) != m.el) {
          var n = a.get(0);
          (n = "LI" != n.tagName && n.firstElementChild && "LI" != n.firstElementChild.tagName ? a.parents("li").get(0) : "LI" == n.tagName || n.firstElementChild ? n.firstElementChild && "LI" == n.firstElementChild.tagName ? a.get(0).firstChild : a.get(0) : a.parents("li").get(0)) && n.parentNode.tagName == t && m.el.contains(n.parentNode) && e.addClass("fr-active");
        }
      }
    };
  }, i.DefineIcon("formatOLSimple", {
    NAME: "list-ol",
    SVG_KEY: "orderedList"
  }), i.RegisterCommand("formatOLSimple", {
    title: "Ordered List",
    type: "button",
    options: {
      "default": "Default",
      circle: "Circle",
      disc: "Disc",
      square: "Square"
    },
    refresh: function (e) {
      (this || _global).lists.refresh(e, "OL");
    },
    callback: function (e, t) {
      (this || _global).lists.format("OL", t);
    },
    plugin: "lists"
  }), i.RegisterCommand("formatUL", {
    title: "Unordered List",
    type: "button",
    hasOptions: function () {
      return (this || _global).opts.listAdvancedTypes;
    },
    options: {
      "default": "Default",
      circle: "Circle",
      disc: "Disc",
      square: "Square"
    },
    refresh: function (e) {
      (this || _global).lists.refresh(e, "UL");
    },
    callback: function (e, t) {
      (this || _global).lists.format("UL", t);
    },
    plugin: "lists"
  }), i.RegisterCommand("formatOL", {
    title: "Ordered List",
    hasOptions: function () {
      return (this || _global).opts.listAdvancedTypes;
    },
    options: {
      "default": "Default",
      "lower-alpha": "Lower Alpha",
      "lower-greek": "Lower Greek",
      "lower-roman": "Lower Roman",
      "upper-alpha": "Upper Alpha",
      "upper-roman": "Upper Roman"
    },
    refresh: function (e) {
      (this || _global).lists.refresh(e, "OL");
    },
    callback: function (e, t) {
      (this || _global).lists.format("OL", t);
    },
    plugin: "lists"
  }), i.DefineIcon("formatUL", {
    NAME: "list-ul",
    SVG_KEY: "unorderedList"
  }), i.DefineIcon("formatOL", {
    NAME: "list-ol",
    SVG_KEY: "orderedList"
  });
});
export default exports;